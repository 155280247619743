<template>
  <b-container class="p-0">
    <!-- last response -->
    <b-row
      v-if="lastResponse.text"
      class="my-2"
    >
      <b-col md="12">
        <div class="mb-1">
          <h3 class="m-0">
            My Last Question
            <b-badge
              v-if="lastResponse.id"
              pill
              variant="secondary"
              class="font-small"
            >
              #{{ lastResponse.id }}
            </b-badge>
          </h3>

          <div v-if="!lastResponse.error">
            <!-- name/date for ongoing pool -->
            <div v-if="lastResponse.cohorts && lastResponse.cohorts.length > 0">
              <h4 class="text-muted inline-block pr-1">
                {{ lastResponse.cohorts[0].name }}
                <b-badge
                  v-if="lastResponse.cohorts[0].progress"
                  pill
                  variant="secondary"
                  class="font-small"
                >
                  Round {{ lastResponse.cohorts[0].progress }}
                </b-badge>
              </h4>
              <span class="text-muted">
                {{ lastResponse.cohorts[0].date_started }}
              </span>
            </div>
            <!-- name/date for winner pool -->
            <div v-if="lastResponse.wins && lastResponse.wins.length > 0">
              <h4 class="text-muted inline-block pr-1">
                {{ lastResponse.wins[0].cohort_name }}
                <b-badge
                  v-if="lastResponse.wins[0].progress"
                  pill
                  variant="secondary"
                  class="font-small"
                >
                  Round {{ lastResponse.wins[0].progress }}
                </b-badge>
              </h4>
              <span class="text-muted">
                {{ lastResponse.wins[0].date_started }}
              </span>
            </div>
          </div>
        </div>

        <b-card class="my-1 last-question">
          <div
            v-if="!lastResponse.error"
            class="question-header"
          >
            <!-- date/pool/prize - cohorts -->
            <div
              v-if="lastResponse.cohorts && lastResponse.cohorts.length > 0"
            >
              <b-row>
                <b-col cols="4">
                  <span class="text-muted d-block">Entries</span>
                  {{ lastResponse.cohorts[0].starting_count }}
                </b-col>
                <b-col
                  cols="4"
                  class="text-center"
                >
                  <span class="text-muted d-block">Survivors</span>
                  {{ lastResponse.cohorts[0].current_count }}
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <span class="text-muted d-block">Prize</span>
                  {{ getReadableDollarAmount(lastResponse.cohorts[0].prize_total_pennies) }}
                </b-col>
              </b-row>
            </div>
            <!-- date/pool/prize - wins -->
            <div
              v-if="lastResponse.wins && lastResponse.wins.length > 0"
            >
              <b-row>
                <b-col cols="4">
                  <span class="text-muted d-block">Entries</span>
                  {{ lastResponse.wins[0].starting_count }}
                </b-col>
                <b-col
                  cols="4"
                  class="text-center"
                >
                  <span class="text-muted d-block">Survivors</span>
                  {{ lastResponse.wins[0].final_count }}
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <span class="text-muted d-block">Prize</span>
                  {{ getReadableDollarAmount(lastResponse.wins[0].share_pennies) }}
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- question text -->
          <b-card-text class="question-text text-center">
            <h4>{{ lastResponse.text }}</h4>
          </b-card-text>

          <!-- question response -->
          <div
            v-if="!lastResponse.error"
            class="question-footer"
          >
            <!-- if user has already responded -->
            <b-row>
              <b-col
                md="12"
                class="text-center"
              >
                <h4>
                  You chose
                </h4>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="btn btn-block btn-secondary text-uppercase cursor-default"
                  :class="{ 'transparent': !lastResponse.response, 'correct-answer': lastResponse.correct_response, 'incorrect-answer': !lastResponse.correct_response }"
                >
                  Yes
                </button>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="btn btn-block btn-secondary text-uppercase cursor-default"
                  :class="{ 'transparent': lastResponse.response, 'correct-answer': !lastResponse.correct_response,'incorrect-answer': lastResponse.correct_response }"
                >
                  No
                </button>
              </b-col>
            </b-row>

            <!-- if user won the pool -->
            <b-row v-if="(lastResponse.wins.length > 0) && lastResponse.response === lastResponse.correct_response">
              <b-col
                md="12"
                class="bg-success text-center mt-2 p-1"
              >
                <h4>
                  <strong>You were right!</strong> You're the winner of the pool.
                </h4>
                You've won {{ getReadableDollarAmount(lastResponse.wins[0].share_pennies) }}
              </b-col>
            </b-row>
            <!-- if user won the pool but got it wrong -->
            <b-row v-if="(lastResponse.wins.length > 0) && lastResponse.response !== lastResponse.correct_response">
              <b-col
                md="12"
                class="bg-success text-center mt-2 p-1"
              >
                <h4>
                  <strong>You were wrong.</strong> The pool is split between all remaining players.
                </h4>
                You've won {{ getReadableDollarAmount(lastResponse.wins[0].share_pennies) }}
              </b-col>
            </b-row>
            <!-- if user moved to next level -->
            <b-row v-if="(lastResponse.wins.length === 0) && lastResponse.response === lastResponse.correct_response">
              <b-col
                md="12"
                class="bg-success text-center mt-2 p-1"
              >
                <h4 class="m-0">
                  <strong>You were right!</strong> You continue to the next level of the pool.
                </h4>
              </b-col>
            </b-row>
            <!-- if user user was wrong -->
            <b-row v-if="(lastResponse.wins.length === 0) && lastResponse.response !== lastResponse.correct_response">
              <b-col
                md="12"
                class="bg-danger text-center mt-2 p-1"
              >
                <h4 class="m-0">
                  <strong>You were wrong. Try again!</strong>
                </h4>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- ongoing pools -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>My Ongoing Pools</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col md="12">
              <!-- pool boxes -->
              <div
                v-for="(pools, poolName) in sortedPoolsOngoing"
                :key="poolName"
              >
                <h3>{{ poolName }}</h3>
                <b-row
                  v-for="pool in pools"
                  :key="`pool_${pool.id}`"
                  class="pool-container"
                >
                  <b-col xs="6">
                    <h4>{{ pool.cohort_name }}</h4>
                    <p><span class="pool-var">Started:</span> {{ pool.date_started }}</p>
                    <p><span class="pool-var">Total Entries:</span> {{ pool.starting_count }}</p>
                  </b-col>
                  <b-col xs="6">
                    <h4>&nbsp;</h4>
                    <p><span class="pool-var">Prize:</span> {{ getReadableDollarAmount(pool.prize_total_pennies) }}</p>
                    <p><span class="pool-var">Survivors:</span> {{ pool.current_count }}</p>
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="sortedPoolsOngoing.length < 1"
              >
                Answer a question to join an active pool!
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- historic pools -->
    <b-row class="my-2">
      <b-col cols="12">
        <h3>My Historic Pools</h3>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col md="12">
              <!-- pools table -->
              <b-table
                class="mb-0 text-center"
                responsive="sm"
                :items="poolsHistoric"
                :fields="poolsHistoricTableFields"
                sort-by="date_ended"
                :sort-desc="true"
                show-empty
              >
                <template #cell(progress)="data">
                  {{ data.item.progress + 1 }}
                </template>

                <template #cell(status)="data">
                  <b-badge
                    v-if="data.item.i_won"
                    pill
                    variant="light-success"
                  >
                    Winner
                  </b-badge>
                  <b-badge
                    v-if="!data.item.i_won"
                    pill
                    variant="light-danger"
                  >
                    Try Again
                  </b-badge>
                </template>

                <template #cell(prize)="data">
                  {{ data.item.i_won_prize_pennies ? getReadableDollarAmount(data.item.i_won_prize_pennies) : '$0' }}<!--
                  --><small>
                    /{{ data.item.prize_total_pennies ? getReadableDollarAmount(data.item.prize_total_pennies) : '$0' }}
                  </small>
                </template>

                <template #empty="">
                  <div class="text-center">
                    No historic pools found
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BCard, BCardText,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import moment from 'moment'

import { isUserLoggedIn } from '@/auth/utils'
import HomeBackground from '@/assets/images/backgrounds/home.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BBadge,
  },
  data() {
    return {
      poolsHistoricTableFields: [
        { key: 'date_started', label: 'Date Started' },
        { key: 'date_ended', label: 'Date Ended' },
        { key: 'cohort_name', label: 'Name' },
        { key: 'starting_count', label: 'Total Players' },
        { key: 'progress', label: 'Rounds' },
        { key: 'status', label: 'Result' },
        { key: 'prize', label: 'Prize Won' },
      ],
      todaysDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'), // TO DELETE
    }
  },
  computed: {
    lastResponse() {
      return this.$store.getters['questions/getLastResponse']
    },
    poolsOngoing() {
      return this.$store.getters['pools/getAllOngoingPools']
    },
    sortedPoolsOngoing() {
      return this.sortPools(this.poolsOngoing)
    },
    currentPool() {
      return this.poolsOngoing[0] || {}
    },
    poolsHistoric() {
      return this.$store.getters['pools/getAllHistoricPools']
    },
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
  mounted() {
    if (!isUserLoggedIn()) {
      this.$router.push('/login')
    }

    this.$store.dispatch('questions/fetchLastResponse')
    this.$store.dispatch('pools/fetchAllOngoingPools')
    this.$store.dispatch('pools/fetchAllHistoricPools')
  },
  methods: {
    sortPools(poolsData) {
      const sorted = {}
      Object.keys(poolsData).forEach(key => {
        const poolItem = poolsData[key]
        if (sorted[poolItem.name]) {
          sorted[poolItem.name].push(poolItem)
        } else {
          sorted[poolItem.name] = [poolItem]
        }
      })
      return sorted || {}
    },

    /* MISCELLANEOUS */

    getReadableDollarAmount(penniesStr) {
      let formattedDollarAmount = '$0'
      if (penniesStr > 0) {
        formattedDollarAmount = (penniesStr / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) // TODO get local currency
      }
      return formattedDollarAmount
    },
    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style scoped>
.inline-block {
  display: inline-block;
}
.transparent {
  opacity: 0.2;
}
</style>
